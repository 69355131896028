import { UseCase } from '../../base/UserCase';
import { Observable} from 'rxjs';
import { PolizaRepository } from '../../repositories/PolizaRepository';
import { Injectable } from '@angular/core';
import { PolizaDTO, GetPolizaDTO } from '../../../data/dto/PolizaDTO';

@Injectable({
    providedIn: 'root'
})
export class CreatePolizaUseCase implements UseCase<GetPolizaDTO, PolizaDTO> {

    constructor(
        private polizaRepository: PolizaRepository,

    ) { }

    execute(poliza: GetPolizaDTO): Observable<PolizaDTO> {
        return this.polizaRepository.createPoliza(poliza);
    }
}
