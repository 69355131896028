import { UseCase } from '../../base/UserCase';
import { Observable} from 'rxjs';
import { QuotationRepository } from '../../repositories/QuotationRepository';
import { Injectable } from '@angular/core';
import { QuotationDTO, QuotationValidateDTO } from '../../../data/dto/QuotationDTO';

@Injectable({
    providedIn: 'root'
})
export class ValidateQuotationUseCase implements UseCase<QuotationValidateDTO, QuotationDTO> {

    constructor(
        private quotationRepository: QuotationRepository,

    ) { }

    execute(request: QuotationValidateDTO): Observable<QuotationDTO> {
        return this.quotationRepository.validateQuotation(request);
    }
}
